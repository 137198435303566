/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';

const QEntity = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7.23548 13.8308L7.45645 13.6098C7.60289 13.4634 7.60289 13.226 7.45645 13.0795L1.87852 7.50155H13.625C13.8321 7.50155 14 7.33364 14 7.12655V6.81405C14 6.60695 13.8321 6.43905 13.625 6.43905H1.87852L7.45645 0.861109C7.60289 0.714672 7.60289 0.477234 7.45645 0.330766L7.23548 0.109828C7.08905 -0.0366094 6.85161 -0.0366094 6.70517 0.109828L0.109828 6.70514C-0.0366094 6.85158 -0.0366094 7.08902 0.109828 7.23548L6.70517 13.8308C6.85161 13.9772 7.08905 13.9772 7.23548 13.8308Z" fill="currentColor" />
  </svg>
);

export default QEntity;
