import QLogotype from 'assets/images/QLogotype';
import classnames from 'classnames';
import React, { ReactElement } from 'react';
import './styles.css';

export interface QModalBackgroundImgProps {
  src: string | null;
  offerId?: string | null;
  children?: ReactElement[] | ReactElement | boolean;
  logo?: string | null;
}

const QModalBackgroundImg = (Props: QModalBackgroundImgProps) => {
  const { src, offerId, logo, children } = Props;

  return (
    <div className={classnames({
      QModalBackgroundImg: true,
      Main: !offerId,
      Offer: offerId,
    })}
    >
      {src &&
        <img className="QModalBackgroundInnerImg" src={src} alt="Imagem de apresentação" loading="lazy" />
      }
      <div className="QModalInner">
        {children}
      </div>
      {logo ? (
        <img src={logo} alt="logo parceiro" className="QLogoParceiro" />
      ) : (
        <QLogotype className="QLogoWhite" />
      )}
    </div>
  );
};

export default QModalBackgroundImg;
