import api from '../index';

async function listCourses(filter: any) {
  return api.get('/learn/courses', { params: filter });
}

async function getCourse(courseId: string) {
  return api.get(`/learn/courses/${courseId}`);
}

async function getEnrolledCourses() {
  return api.get('/learn/courses/enrollments');
}

async function getSuggestedCourses() {
  return api.get('/learn/courses/suggestions');
}

async function getEnrolledCourse(courseId: string) {
  return api.get(`/learn/courses/enrollments/${courseId}`);
}

async function getContent(contentId: string, options = {}) {
  return api.get(`/learn/contents/${contentId}`, { params: options });
}

async function getResource(resourceId: string, options = {}) {
  return api.get(`/learn/resources/${resourceId}`, { params: options });
}

async function createView(contentId: string) {
  return api.post(`/learn/contents/${contentId}/view`);
}

async function destroyView(contentId: string) {
  return api.delete(`/learn/contents/${contentId}/view`);
}

interface CreateRatingInterface {
  kind: string;
  reference: string;
  score: number;
  motive: string | undefined | null;
}

async function createRating({ kind, reference, score, motive }: CreateRatingInterface) {
  // scores: like => 1; dislike => -1; none => 0;
  return api.post('/learn/rating', { kind, reference, score, motive });
}

async function getCertificate(enrollmentId: string) {
  return api.get(`/learn/certificates/${enrollmentId}`);
}

async function getCertificateImage(enrollmentId: string) {
  return api.get(`/learn/certificates/${enrollmentId}/image`);
}

async function createCertificate({ enrollmentId, name, documentCode }: any) {
  return api.post('/learn/certificates', {
    name,
    document: { type: "cpf", code: documentCode },
    id: enrollmentId,
  });
}

async function updateAttempt({ attempt, answers, completed }:any) {
  return api.put(`/learn/attempts/${attempt.id}`, {
    answers,
    completed,
  });
}

export default {
  listCourses,
  getEnrolledCourses,
  getSuggestedCourses,
  getEnrolledCourse,
  getContent,
  getResource,
  getCourse,
  createView,
  destroyView,
  createRating,
  getCertificate,
  getCertificateImage,
  createCertificate,
  updateAttempt,
};
