/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';

const QMinimize = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M4.87664 9.99533H3.60464C3.39247 9.99533 3.18898 9.91105 3.03895 9.76102C2.88892 9.61099 2.80464 9.4075 2.80464 9.19533C2.80464 8.98316 2.88892 8.77967 3.03895 8.62965C3.18898 8.47962 3.39247 8.39533 3.60464 8.39533H6.80464C6.90918 8.39659 7.01246 8.41834 7.10864 8.45933C7.30412 8.54051 7.45946 8.69585 7.54064 8.89133C7.58163 8.98751 7.60337 9.09079 7.60464 9.19533L7.60464 12.3953C7.60464 12.6075 7.52035 12.811 7.37032 12.961C7.22029 13.111 7.01681 13.1953 6.80464 13.1953C6.59247 13.1953 6.38898 13.111 6.23895 12.961C6.08892 12.811 6.00464 12.6075 6.00464 12.3953L6.00464 11.1233L1.37264 15.7633C1.29827 15.8383 1.20979 15.8978 1.1123 15.9384C1.01481 15.9791 0.910248 16 0.804639 16C0.699029 16 0.594465 15.9791 0.496977 15.9384C0.39949 15.8978 0.311009 15.8383 0.236639 15.7633C0.161656 15.689 0.102141 15.6005 0.0615257 15.503C0.0209108 15.4055 0 15.3009 0 15.1953C0 15.0897 0.0209108 14.9852 0.0615257 14.8877C0.102141 14.7902 0.161656 14.7017 0.236639 14.6273L4.87664 9.99533ZM9.19673 2.80324C9.4089 2.80324 9.61238 2.88753 9.76241 3.03756C9.91244 3.18759 9.99673 3.39107 9.99673 3.60324V4.87524L14.6287 0.235241C14.7794 0.0845985 14.9837 -3.07602e-05 15.1967 -3.0756e-05C15.4098 -3.07518e-05 15.6141 0.0845985 15.7647 0.235241C15.9154 0.385884 16 0.590201 16 0.803242C16 1.01628 15.9154 1.2206 15.7647 1.37124L11.1247 6.00324H12.3967C12.6089 6.00324 12.8124 6.08753 12.9624 6.23756C13.1124 6.38759 13.1967 6.59107 13.1967 6.80324C13.1967 7.01542 13.1124 7.2189 12.9624 7.36893C12.8124 7.51896 12.6089 7.60324 12.3967 7.60324H9.19673C9.09219 7.60198 8.98891 7.58024 8.89273 7.53924C8.69725 7.45806 8.54191 7.30272 8.46073 7.10724C8.41973 7.01106 8.39799 6.90778 8.39673 6.80324V3.60324C8.39673 3.39107 8.48101 3.18759 8.63104 3.03756C8.78107 2.88753 8.98455 2.80324 9.19673 2.80324Z" fill="#637281" />
  </svg>
);

export default QMinimize;
