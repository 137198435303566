import { OfferType } from "entities/checkout";
import { CourseShowType } from "entities/course";
import CourseServices from 'services/courses';
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from "react";
import OfferService from 'services/checkout/offers';

type OfferContextProps = {
  findOffer: (offerId: string) => Promise<void>
  offer: OfferType
  course: CourseShowType
  cleanOffer: () => void
}

const OfferContext = createContext<OfferContextProps>({} as OfferContextProps);

export const OfferContextProvider = ({ children }: { children: ReactNode }) => {
  const [offer, setOffer] = useState<OfferType>(null!);
  const [course, setCourse] = useState<CourseShowType>(null!);

  const findOffer = useCallback(async (offerId: string) => {
    try {
      const offerData = await OfferService.show(offerId);

      setOffer(offerData);

      if (["course", "course_certificate_referral"].includes(offerData.item.kind)) {
        const { data } = await CourseServices.getCourse(offerData.item.reference);
        setCourse(data);
      } else {
        setCourse({
          id: offerData.item.id,
          instructors: [],
          productor: { id: "", name: "", slug: "", images: {}},
          duration: 60,
          name: "Assinatura",
          tags: [],
        });
      }
    } catch (error) {
      console.log('Erro ao carregar oferta');
    }
  }, []);

  const cleanOffer = useCallback(() => setOffer(null!), []);

  const config = useMemo(() => ({ findOffer, offer, course, cleanOffer }), [offer, course, cleanOffer, findOffer])

  return (
    <OfferContext.Provider value={config}>
      {children}
    </OfferContext.Provider>
  )
};

export const useOfferContext = () => useContext(OfferContext);
