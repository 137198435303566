import axios from 'axios';

const Iterable = axios.create({
  baseURL: 'https://api.iterable.com',
});

Iterable.defaults.headers.common['Api-Key'] = process.env.REACT_APP_ITERABLE_API_KEY!;

async function track({ email, name, data }: any) {
  return Iterable.post(`/api/events/track`, {
    email,
    eventName: name,
    dataFields: data,
  });
}

export default {
  track,
};
