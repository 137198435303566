import React, { CSSProperties, ReactNode, useState } from 'react';
import classNames from 'classnames';
import './styles.css';
import QModalCloseButton from '../QModalCloseButton';
import QModalResize from '../QModalResize';

export interface QCardProps {
  children?: ReactNode[] | ReactNode | undefined,
  title?: string,
  showHeader?: boolean,
  showResize?: boolean,
  style?: CSSProperties,
  customClass?: string,
  showCloseButton?: boolean;
  fnCloseButton?: () => void;
  initialStatus?: boolean
}

const QCard = (Props: QCardProps) => {
  const {
    children,
    title,
    style,
    customClass,
    showCloseButton,
    fnCloseButton,
    showHeader,
    showResize,
    initialStatus
  } = Props;
  const [status, setStatus] = useState<boolean>(initialStatus || false);

  const handleResize = () => {
    setStatus(!status)
  }

  return (
    <div
      className={classNames(
        'QCardContainer',
        customClass,
        {
          'QCardMinimized': status
        }
      )}
      style={{ ...style }}
    >
      {showHeader && (
        <div className="QCardHeader">
          <span className="QCardTitle">
            {title}
          </span>
          {showCloseButton && (
            <>
              {
                showResize && <QModalResize className="QCardResizeButton" fnCallback={() => handleResize()} initialStatus={initialStatus} />
              }
              <QModalCloseButton className="QCardCloseButton" fnCallback={fnCloseButton} />
            </>
          )}
        </div>
      )}
      {children}
    </div>
  );
};

export default QCard;
