import SpinnerLoading from 'assets/images/LoaderSpinning.png';
import SuccessImage from 'assets/images/newsletter/successImage.png';
import QCard from 'components/atoms/QCard';
import QModalButton from 'components/atoms/QModalButton';
import QModalContainer from 'components/atoms/QModalContainer';
import QModalInput from 'components/atoms/QModalInput';
import React, { FormEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import IterableAPI from 'services/iterable';
import { useAppDispatch } from 'stores';
import { hideNewsletter } from 'stores/modals';
import { RootState } from 'stores/rootReducers';
import { subscribeNewsletter } from 'stores/user';
import { emailValidation, nameValidation } from 'utils/validation';
import './styles.css';

const QModalNewsletter: React.FC = () => {
  const dispatch = useAppDispatch;
  const User = useSelector((state: RootState) => state.user);
  const { showNewsletterModal } = useSelector((state: RootState) => state.modal);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [form, setForm] = useState({
    name: '',
    email: ''
  })

  useEffect(() => {
    if (User.authenticated) {
      setForm({
        name: User.name,
        email: User.email || ''
      })
    }
  }, [])
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setSuccess(true)
      setTimeout(() => {
        setLoading(false)
        setSuccess(false)
        dispatch(subscribeNewsletter())
        dispatch(hideNewsletter())
      }, 2500)
    }, 1000)
    IterableAPI.track({
      email: form.email,
      name: 'newsletter_subscribe',
      data: {
        name: form.name,
        email: form.email
      }
    })
  }

  const handleDisable = () => {
    if (emailValidation(form.email)) {
      return true
    }

    if (nameValidation(form.name)) {
      return true
    }
    return false
  }
  return (
    <QModalContainer id="QModalNewsletter" type='Default' open={showNewsletterModal}>
      <QCard customClass='QModalNewsletter' title='FIQUE SABENDO!' showCloseButton showHeader fnCloseButton={() => dispatch(hideNewsletter())}>
        <div className='QModalNewsletterContainer'>
          {loading &&
            <div className='QCardNewsletterLoadingOverlay'>
              {!success &&
                <img className='QCardNewsletterSpinner' src={SpinnerLoading} alt="Loading" />
              }
              {
                success &&
                <>
                  <img className='QCardNewsletterSuccess' src={SuccessImage} alt="Sucesso!" />
                  <span className='QCardNewsletterSuccessMessage'>Boa! Agora você receberá nossas novidades em primeira mão!</span>
                </>
              }
            </div>
          }
          <span className='QCardNewsletterDescription'>Cursos, promoções e novidades no seu e-mail! Acompanhe as novidades do Qcarreiras</span>
          <form onSubmit={handleSubmit}>
            <span className='QCardNewsletterLabel'>Seu nome</span>
            <QModalInput
              className='QCardNewsletterInput'
              type='text'
              placeholder='Digite seu nome...'
              value={form.name}
              handleOnChange={e => setForm({ ...form, name: e.target.value })}
            />
            <span className='QCardNewsletterLabel'>Seu melhor e-mail</span>
            <QModalInput
              className='QCardNewsletterInput'
              type='text'
              placeholder='Digite seu email...'
              value={form.email}
              handleOnChange={e => setForm({ ...form, email: e.target.value })}
            />

            <QModalButton
              className='QCardNewsletterCTA'
              type='submit'
              disabled={handleDisable()}
            >
              Quero novidades!
              <svg
                className="fill-current"
                width="14"
                height="12"
                viewBox="0 0 14 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.17899 10.128L10.26 7.083L1.06599 7.041C0.925303 7.04047 0.786103 7.01221 0.656354 6.95783C0.526606 6.90344 0.408855 6.82401 0.309841 6.72407C0.210828 6.62412 0.132495 6.50564 0.0793262 6.37539C0.0261574 6.24513 -0.000803513 6.10568 -1.3652e-05 5.965C0.000641143 5.8244 0.0289997 5.6853 0.0834409 5.55567C0.137882 5.42604 0.217338 5.3084 0.317268 5.2095C0.417197 5.11059 0.53564 5.03235 0.665826 4.97924C0.796012 4.92613 0.935388 4.89921 1.07599 4.9L10.268 4.941L7.22799 1.865C7.1289 1.76523 7.05045 1.64691 6.99712 1.51681C6.9438 1.3867 6.91663 1.24736 6.91719 1.10675C6.91775 0.966143 6.94601 0.827022 7.00037 0.697344C7.05473 0.567666 7.13411 0.449974 7.23399 0.350995C7.43591 0.151199 7.70888 0.0397146 7.99293 0.0410271C8.27699 0.0423396 8.54892 0.156342 8.74899 0.357995L13.749 5.407C13.9129 5.57276 14.0042 5.79684 14.0029 6.02994C14.0016 6.26305 13.9077 6.48608 13.742 6.65L8.68899 11.65C8.48723 11.8497 8.21443 11.9612 7.93054 11.9599C7.64664 11.9586 7.37488 11.8446 7.17499 11.643C7.07574 11.5433 6.99711 11.425 6.9436 11.2949C6.89009 11.1647 6.86274 11.0254 6.86311 10.8847C6.86348 10.744 6.89157 10.6047 6.94577 10.4749C6.99997 10.3451 7.07922 10.2272 7.17899 10.128Z"
                />
              </svg>
            </QModalButton>
          </form>
        </div>
      </QCard>
    </QModalContainer>
  );
}

export default QModalNewsletter;