export const convertFormToInfo = (data: any) => {
  const { score, questions, version_id } = data;

  const parsedAnswers = questions.map((question: any) => {
    const { id, answer } = question.table;

    return { question_id: id, answer }
  })

  return {
    score,
    version: version_id,
    answers: parsedAnswers
  }
}