/* eslint jsx-a11y/label-has-associated-control: 0 */
import QArrow from 'assets/icons/QArrow';
import LadderCertificate from 'assets/images/ladderCertificate.png';
import LadderMobileCertificate from 'assets/images/ladderMobileCertificate.png';
import LadderWithStarCertificate from 'assets/images/ladderWithStarCertificate.png';
import StarsCertificate from 'assets/images/starsCertificate.png';
import CertificateWarning from 'assets/images/certificateWarning.png';
import CertificateChecked from 'assets/images/certificateChecked.png';
import CertificateDownloadPDF from 'assets/images/certificateDownloadPDF.png';
import CertificateDownloadPNG from 'assets/images/certificateDownloadPNG.png';
import QCard from 'components/atoms/QCard';
import QLoadingSpinner from 'components/atoms/QLoadingSpinner';
import QModalButton from 'components/atoms/QModalButton';
import QModalContainer from 'components/atoms/QModalContainer';
import QModalInput from 'components/atoms/QModalInput';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'stores';
import { addInputError, removeInputError } from 'stores/errors';
import { setShowCertificateModal } from 'stores/modals';
import { RootState } from 'stores/rootReducers';
import { formatInput } from 'utils/inputFormat';
import { verifyCPF } from 'utils/validation';
import './styles.css';
import QChevron from 'assets/icons/QChevron';
import { getCourse } from 'stores/courses';
import CoursesAPI from 'services/courses';

const LoadMessages = [
  'Contabilizando aulas concluídas...',
  'Verificando seus dados...',
  // 'Gerando certificado...',
]

const QCertificateModal = () => {
  const dispatch = useAppDispatch;
  const { showCertificateModal } = useSelector((state: RootState) => state.modal);
  const { course } = useSelector((state: RootState) => state.courses);
  const { inputErrors } = useSelector((state: RootState) => state.errors);
  const { name } = useSelector((state: RootState) => state.user);
  const [loadMessageIndex, setLoadMessageIndex] = useState(0);
  const [form, setForm] = useState({
    name: '',
    documentCode: ''
  })

  const handleClose = () => {
    dispatch(setShowCertificateModal({ active: false, step: 'firstStep' }))
  }

  const stepTo = (step: string) => {
    dispatch(setShowCertificateModal({ active: true, step }))
  }

  const generate = async () => {
    stepTo('loading');

    try {
      await CoursesAPI.createCertificate({
        enrollmentId: course!.certificate.id,
        ...form
      });

      handleClose();

      dispatch(getCourse(course!.id));
    } catch {
      stepTo('thirdStep');
    }
  }

  const downloadDocument = async () => {
    const { data } = await CoursesAPI.getCertificate(course!.certificate.id);

    window.open(data.url, "_blank")
  }

  const downloadImage = async () => {
    const { data } = await CoursesAPI.getCertificateImage(course!.certificate.id);

    window.open(data.url, "_blank")
  }

  const validateCPF = (e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
    const errorMessage = verifyCPF(e.target.value.replace(/[\D+]/g, ''))
    if (!errorMessage) {
      return dispatch(removeInputError('certificateCPF'));
    }

    return dispatch(addInputError({
      'certificateCPF': errorMessage,
    }))
  }

  const handleCPFStatus = (): 'Error' | 'Success' | undefined => {
    if (inputErrors.certificateCPF) {
      return 'Error'
    }

    if (form.documentCode && !inputErrors.certificateCPF) {
      return 'Success'
    }

    return undefined
  }

  const handleSubmitForm = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  }, [])


  const renderFirstStep = () => (
    <>
      <div className="QCertificateModalLeft">
        <img className="QCertificateStars" src={StarsCertificate} alt="Estrelas" />
        <span className="QCertificateTitle">Parabéns,<span className="QCertificateUser">{name}</span><br /> Você concluiu todas as aulas</span>
        <span className="QCertificateTitleDescription">Agora falta pouco para você ter acesso <br />ao seu certificado.</span>
        <QModalButton className="QCertificateFirstStepButton" onClick={() => stepTo('secondStep')}>Gerar certificado</QModalButton>
        <img className='QCertificateLadderMobileImage' src={LadderMobileCertificate} alt="Goals" />
      </div>
      <div className="QCertificateModalRight">
        <img className='QCertificateLadderImage' src={LadderCertificate} alt="Goals" />
      </div>
    </>
  )

  const renderSecondStep = () => (
    <>
      <div className="QCertificateModalLeft">
        <div className="QCertificateBackbuttonContainer">
          <QModalButton className='QCertificateBackbutton' onClick={() => stepTo('firstStep')}>
            <QArrow className="QCertificateArrowIcon" />
          </QModalButton>
          <span className="QCertificateSubTitle">{name}, informe o seu nome completo e CPF</span>
        </div>
        <span className="QCertificateSubTitleDescription">Esses dados serão exibidos no seu certificado, então preencha com atenção.</span>

        <form className="QCertificateForm" onSubmit={handleSubmitForm}>
          <label className="QCertificateInputTitle">
            NOME COMPLETO *
            <QModalInput
              placeholder="Digite seu nome aqui..."
              type="text"
              className='QCertificateInput'
              value={form.name}
              required
              handleOnChange={(e) => { setForm({ ...form, name: e.target.value }); }}
            />
          </label>

          <label className="QCertificateInputTitle">
            CPF *
            <QModalInput
              className='QCertificateInput'
              placeholder="000.000.000-00"
              value={formatInput(form.documentCode, '###.###.###-##')}
              type="text"
              handleOnChange={(e) => {
                if (e.target.value.length > 14) {
                  return;
                }
                validateCPF(e);
                setForm({ ...form, documentCode: e.target.value.replace(/[\D+]/g, '') });
              }}
              status={handleCPFStatus()}
            />
          </label>

          <QModalButton type="submit" disabled={form.name === '' || Boolean(inputErrors.certificateCPF)} className="QCertificateSubmitButton" onClick={() => stepTo('thirdStep')}>Continuar</QModalButton>
        </form>

      </div>
      <div className="QCertificateModalRight">
        <img className='QCertificateLadderImage' src={LadderWithStarCertificate} alt="Goals" />
      </div>
    </>
  )

  const renderThirdStep = () => (
    <div className="QCertificateThirdStepContainer">
      <div className="QCertificateThirdStepBackTitle">
        <QModalButton className='QCertificateBackbutton'>
          <QArrow className="QCertificateArrowIcon" />
        </QModalButton>
        <span className="QCertificateSubTitle">{name}, está tudo correto?</span>
      </div>
      <img className="QCertificateWarningImage" src={CertificateWarning} alt="Imagem de atenção" />
      <span className="QCertificateThirdStepText">Os dados informados não poderão ser alterados depois que você confirmar.</span>
      <div className="QCertificateUserInfoBox">
        <span className="QCertificateUserName">{form.name}</span>
        <span className="QCertificateUserDocument">
        {form.documentCode.slice(0,3)}.{form.documentCode.slice(3,6)}.{form.documentCode.slice(6,9)}-{form.documentCode.slice(9,11)}</span>
      </div>
      <div className="QCertificateCTAContainer">
        <QModalButton className="QCertificateChangeInfo" onClick={() => stepTo('secondStep')}>Alterar dados</QModalButton>
        <QModalButton className="QCertificateGenerateAction" onClick={generate}>Sim, os dados estão corretos</QModalButton>
      </div>
    </div>
  )

  const renderLastStep = () => (
    <div className="QCertificateLastStepContainer">
      <img className="QCertificateSuccessImage" src={CertificateChecked} alt="Sucesso!" />
      <span className="QCertificateLastStepTitle">Certificado gerado com sucesso!</span>
      <span className="QCertificateLastStepDescription">Agora é só escolher o formato que você<br />quer baixar.</span>
      {course!.certificate.formats.includes("image") && (
        <button type="button" className="QCertificateDownloadContainer" onClick={downloadImage}>
          <img src={CertificateDownloadPNG} alt="Download JPG" />
          <div className="QCertificateDownloadTextContainer">
            <span className="QCertificateDownloadType">JPG</span>
            <span className="QCertificateDownloadHint">Bom para compartilhar</span>
          </div>
          <QChevron className="QCertificateChevron" />
        </button>
      )}
      {course!.certificate.formats.includes("document") && (
        <button type="button" className="QCertificateDownloadContainer" onClick={downloadDocument}>
          <img src={CertificateDownloadPDF} alt="Download PDF" />
          <div className="QCertificateDownloadTextContainer">
            <span className="QCertificateDownloadType">PDF</span>
            <span className="QCertificateDownloadHint">Bom para imprimir</span>
          </div>
          <QChevron className="QCertificateChevron" />
        </button>
      )}
    </div>
  )

  const handleLoadingMessage = () => {
    setTimeout(() => {
      setLoadMessageIndex((loadMessageIndex + 1) % LoadMessages.length)
    }, 5000)
  }

  const renderLoading = () => {
    handleLoadingMessage();

    return (
      <div className="QCertificateLoadingContainer">
        <QLoadingSpinner />
        <span className="QCertificateLoadingText">{LoadMessages[loadMessageIndex]}</span>
      </div>
    )
  }

  const steps = {
    'firstStep': renderFirstStep,
    'secondStep': renderSecondStep,
    'thirdStep': renderThirdStep,
    'lastStep': renderLastStep,
    'loading': renderLoading,
  }

  return (
    <QModalContainer open={showCertificateModal.active} type="Default">
      <QCard
        title="Gerar certificado"
        showCloseButton
        showHeader
        customClass="QCertificateModalCard"
        fnCloseButton={handleClose}
      >
        <div className="QCertificateModalContainer">
          {
            steps[showCertificateModal.step]()
          }
        </div>
      </QCard>
    </QModalContainer >
  );
}

export default QCertificateModal;
