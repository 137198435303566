import { createAsyncThunk, createSlice, PayloadAction  } from '@reduxjs/toolkit';
import { EmploymentAuthResponse, EmploymentAuthRequest } from 'entities/employment';
import EmploymentApi from 'services/employment';
import { ApiErrorType } from 'entities/error';
import { showLoading, hideLoading } from 'stores/modals';
import { parseInsightsParams } from './utils'; 

export const insightsGenerate = createAsyncThunk<
  any,
  EmploymentAuthRequest,
  {
    rejectValue: ApiErrorType,
  }
>(
  'insights/generate',
  async (_payload: EmploymentAuthRequest, thunkAPI) => {
    thunkAPI.dispatch(showLoading());
    return (
      EmploymentApi.showUserInfo()
        .then((response) => {
          const params = parseInsightsParams(response.data);
          return EmploymentApi.createInsights(params).then((res) => {
            thunkAPI.dispatch(hideLoading());
            return res.data
          }).catch((error) => {
            thunkAPI.dispatch(hideLoading());
            return thunkAPI.rejectWithValue(error.response.data);
          })
        })
        .catch((error) => {
          thunkAPI.dispatch(hideLoading());
          return thunkAPI.rejectWithValue(error.response.data);
        })
    );
  },
);

const initialState: { redirect: boolean, authenticated: boolean, totalQuestions: number, insightsInfo: any, status: '' | 'ongoing_review' | 'review' | 'start' | 'instructions' | 'ongoing' | 'finished', currentQuestion: any } = {
  authenticated: false,
  status: 'start',
  insightsInfo: null,
  redirect: false,
  currentQuestion: '',
  totalQuestions: 0
};

export const insightsSlice = createSlice({
  name: 'insights',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<EmploymentAuthResponse>) => {
      const { token } = action.payload;
      state.authenticated = !!token;
    },
    setRedirect: (state, action: PayloadAction<boolean>) => {
      state.redirect = action.payload;
    },
    setCurrentQuestion: (state, action: PayloadAction<any>) => {
      state.status = action.payload.status || 'ongoing';
      state.currentQuestion = action.payload.question;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(insightsGenerate.fulfilled, (state, action) => {
        state.redirect = false;
        state.insightsInfo = action.payload || {};
      })
      .addCase(insightsGenerate.rejected, (state, action) => {
        state.redirect = true;
      })
  }
});

export const {
  setAuth,
  setRedirect,
  setCurrentQuestion
} = insightsSlice.actions;

export default insightsSlice.reducer;
