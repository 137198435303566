import EmploymentApi from './api';
import Api from '../index';

async function generateToken() {
  return Api.post('/auth/users/employment');
}

async function createUserInfo({ form }: { form: { score: string, version: string, answers: Array<any> }}) {
  return Api.post('/employment/infos', {
    form
  });
}

async function updateUserInfo({ form }: { form: { score: string, version: string, answers: Array<any> }}) {
  return Api.put('/employment/infos', {
    form
  });
}

async function showUserInfo() {
  return Api.get('/employment/infos');
}

async function createInsights({ infos, job }: { infos: any, job: string }) {
  return Api.post('/employment/insights', {
    job,
    infos
  });
}

async function createForm() {
  return EmploymentApi.post('/employment/forms');
}

async function answerForm({ answers }: any) {
  return EmploymentApi.put(`/employment/forms`, {
    answers
  });
}

async function showForm() {
  return EmploymentApi.get(`/employment/forms`);
}

async function completeForm() {
  return EmploymentApi.post(`/employment/forms/complete`);
}

export default {
  generateToken,
  createForm,
  answerForm,
  showForm,
  completeForm,
  createUserInfo,
  updateUserInfo,
  showUserInfo,
  createInsights
};
