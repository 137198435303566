const EMPLOYMENT_LOCAL_KEY = 'employment_token';

export const getFormToken = () => (
  localStorage.getItem(EMPLOYMENT_LOCAL_KEY)
)

export const setFormToken = ({ token }: { token: string }) => {
  localStorage.setItem(EMPLOYMENT_LOCAL_KEY, token);
}

export const cleanFormToken = () => {
  localStorage.removeItem(EMPLOYMENT_LOCAL_KEY);
}