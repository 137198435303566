import classnames from 'classnames';
import React from 'react';
import StatusIcon from './statusIcon';
import './styles.css';
import { QModalInputProps } from './types';

const QModalInput: React.FC<QModalInputProps> = (Props: QModalInputProps) => {
  const {
    placeholder,
    type,
    status,
    errorMessage,
    style,
    handleOnChange,
    handleOnBlur,
    handleOnFocus,
    value,
    className,
    id,
    defaultValue,
    required,
    minlength,
    name,
    autocomplete
  } = Props;

  const styleInput = classnames(
    'QModalInput',
    { error: status === 'Error' },
    { success: status === 'Success' },
  );

  return (
    <>
      <div
        className={classnames(
          'QModalInputContainer',
          className,
        )}
        style={{ ...style }}
      >
        <input
          className={styleInput}
          type={type}
          value={value}
          name={name}
          autoComplete={autocomplete}
          placeholder={placeholder}
          defaultValue={defaultValue}
          required={required}
          minLength={minlength}
          onChange={(e) => handleOnChange?.(e)}
          onBlur={(e) => handleOnBlur?.(e)}
          onFocus={(e) => handleOnFocus?.(e)}
          id={id}
        />
        {status && <StatusIcon status={status} />}
      </div>
      {errorMessage && <span className="QInputErrorMessage">{errorMessage}</span>}
    </>
  );
};

export default QModalInput;
