import DefaultMainBgImg from 'assets/images/defaultLoginImage.png';
import QFacebookLogo from 'assets/images/QFacebookLogo';
import QGoogleLogo from 'assets/images/QGoogleLogo';
import QModalBackgroundImg from 'components/atoms/QModalBackgroundImg';
import QModalBodyLeft from 'components/atoms/QModalBodyLeft';
import QModalBodyRight from 'components/atoms/QModalBodyRight';
import QModalButton from 'components/atoms/QModalButton';
import QModalContainer from 'components/atoms/QModalContainer';
import QModalDoubleDivider from 'components/atoms/QModalDoubleDivider';
import QModalHeader from 'components/atoms/QModalHeader';
import QModalInput from 'components/atoms/QModalInput';
import QModalDescriptions from 'components/atoms/QTypography/QModalDescriptions';
import QModalLink from 'components/atoms/QTypography/QModalLink';
import QModalTitle from 'components/atoms/QTypography/QModalTitle';
import QModalOfferInformation from 'components/molecules/QModalOfferInformation';
import { useOfferContext } from 'context/OfferContext';
import { useToastContext } from 'context/ToastContext';
import { LoginRequest, loginValidation } from 'entities/user';
import React, { FormEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'stores';
import { addInputError, clearInputErrors, removeInputError } from 'stores/errors';
import { hideLoginModal, setShowForgotModal, showCadastroModal } from 'stores/modals';
import { RootState } from 'stores/rootReducers';
import { signInUser, signInUserFacebook, signInUserGoogle, setRedirectLocation } from 'stores/user';
import CourseAPI from 'services/courses';
import { emailValidation, passwordValidation } from 'utils/validation';
import loadGoogleSignIn from 'scripts/googleSignIn';
import './styles.css';

export interface QLoginModalProps {
  open: boolean
  fnCallBack: React.Dispatch<React.SetStateAction<boolean>>
}

const defaultErrors = {
  email: '',
  password: '',
};

const defaultLoginRequest = {
  email: '',
  password: '',
  error: defaultErrors
};

const QLoginModal = (Props: QLoginModalProps) => {
  const { open, fnCallBack } = Props;
  const location = useLocation();
  const navigate = useNavigate();
  const { openToast } = useToastContext();
  const { offer } = useOfferContext();
  const { showLoginModal } = useSelector((state: RootState) => state.modal);
  const { inputErrors } = useSelector((state: RootState) => state.errors);
  const [loginRequest, setLoginRequest] = useState<LoginRequest>(defaultLoginRequest);
  const dispatch = useAppDispatch;
  const title = showLoginModal.offerId ? 'Faça login para continuar comprando este curso' : 'Faça login para continuar acessando';

  useEffect(() => {
    loadGoogleSignIn(null);
  });

  useEffect(() => {
    if (!open) {
      setLoginRequest(defaultLoginRequest);
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      dispatch(setRedirectLocation(window.location.pathname));
    }
  }, [open]);

  useEffect(() => {
    dispatch(clearInputErrors())
  }, [])

  const bgImage = offer?.images?.modal || DefaultMainBgImg;

  const afterLogin = () => {
    CourseAPI.getEnrolledCourses().then((data) => {
      if (location.pathname === '/') {
        CourseAPI.getEnrolledCourses().then(({ data }) => {
          if (data.length > 0) {
            navigate('/meus-cursos');
          }
        });
      }
    })
  }

  const handleEntrar = (e: FormEvent) => {
    e.preventDefault();

    const { errors, isValid } = loginValidation(loginRequest);

    if (!isValid) {
      return setLoginRequest({ ...loginRequest, error: errors });
    }

    setLoginRequest({ ...loginRequest, error: defaultErrors });

    return dispatch(signInUser(loginRequest))
      .then((response) => {
        if (signInUser.fulfilled.match(response)) {
          dispatch(hideLoginModal());
          afterLogin();
        }

        if (response.type === 'user/signin/rejected') {
          openToast({
            message: 'E-mail ou senha incorretos. Verifique os dados informados.',
            type: 'error'
          });
        }
      })
  };

  const handleLoginGoogle = (token: string) => (
    dispatch(signInUserGoogle({ token }))
      .then((response) => {
        if (signInUserGoogle.fulfilled.match(response)) {
          dispatch(hideLoginModal());
          afterLogin();
        }
      })
  );

  const handleLoginFacebook = (token: string) => (
    dispatch(signInUserFacebook({ token }))
      .then((response) => {
        if (signInUserFacebook.fulfilled.match(response)) {
          dispatch(hideLoginModal());
          afterLogin();
        }
      })
  );

  const validateEmail = (e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
    const errorMessage = emailValidation(e.target.value)
    if (!errorMessage) {
      return dispatch(removeInputError('loginEmail'));
    }

    return dispatch(addInputError({
      'loginEmail': errorMessage,
    }))
  }

  const handleEmailStatus = (): 'Error' | 'Success' | undefined => {
    if (loginRequest.error.email || inputErrors.loginEmail) {
      return 'Error'
    }

    if (loginRequest.email && !inputErrors.loginEmail) {
      return 'Success'
    }

    return undefined
  }

  const validatePassword = (e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
    const errorMessage = passwordValidation(e.target.value)

    if (!errorMessage) {
      return dispatch(removeInputError('loginPassword'));
    }

    return dispatch(addInputError({
      'loginPassword': errorMessage,
    }))
  }

  const handlePasswordStatus = (): 'Error' | 'Success' | undefined => {
    if (loginRequest.error.password || inputErrors.loginPassword) {
      return 'Error'
    }

    if (loginRequest.password && !inputErrors.loginPassword) {
      return 'Success'
    }

    return undefined
  }

  const handleDisabledButton = () => {
    if (
      !inputErrors.loginEmail &&
      !inputErrors.loginPassword &&
      loginRequest.password &&
      loginRequest.email) {
      return false;
    }
    return true;
  }


  return (
    <QModalContainer open={open} type="Login">
      <QModalHeader fnCallback={() => {
        dispatch(clearInputErrors());
        fnCallBack(false);
      }} />

      <div className="QBodyContainer">
        <QModalBodyLeft>
          <QModalBackgroundImg src={bgImage} offerId={offer?.id} logo={offer?.images?.login_logo} >
            {
              offer
              && <QModalOfferInformation offer={offer} />
            }
          </QModalBackgroundImg>
        </QModalBodyLeft>

        <QModalBodyRight>
          <form onSubmit={handleEntrar}>
            <QModalTitle>
              {title}
            </QModalTitle>

            <div className="QMensagemLogin">
              <QModalDescriptions>
                Não tem um cadastro? &nbsp;
              </QModalDescriptions>
              <QModalLink onClick={() => {
                dispatch(showCadastroModal(offer?.id));
              }}
              >
                Cadastre-se
              </QModalLink>
            </div>

            <div className="QSocialLoginContainer">
            <QModalButton
              style={{ backgroundColor: '#FFFFFF', color: '#374151', border: '1px solid #D3D6DC'}}
              onClick={() => {
                const domain = `${window.location.protocol}//${window.location.host}`;
                const redirect_uri = `${domain}/oauth/google`;

                window.auth2.grantOfflineAccess({redirect_uri});
              }}
            >
              <QGoogleLogo className="QGoogleLogo" />
              Login com Google
            </QModalButton>

              <QModalButton
                style={{ backgroundColor: '#1977F3', marginTop: 16 }}
                onClick={() => window.FB.login((response: any) => {
                  const { authResponse } = response;
                  if (authResponse) {
                    handleLoginFacebook(response.authResponse.accessToken);
                  }
                }, { scope: 'public_profile,email' })}
              >
                <QFacebookLogo className="QFacebookLogo" />
                Login com Facebook
                <div />
              </QModalButton>
            </div>

            <QModalDoubleDivider>
              <QModalDescriptions style={{ fontSize: 14 }}>
                ou
              </QModalDescriptions>
            </QModalDoubleDivider>

            <QModalInput
              placeholder="E-mail"
              type="text"
              handleOnChange={(e) => {
                validateEmail(e)
                setLoginRequest({ ...loginRequest, email: e.target.value })
              }}
              handleOnBlur={validateEmail}
              errorMessage={loginRequest.error.email || inputErrors.loginEmail}
              status={handleEmailStatus()}
            />
            <QModalInput
              placeholder="Senha - Mínimo de 8 caracteres"
              type="password"
              autocomplete="on"
              handleOnChange={(e) => {
                validatePassword(e)
                setLoginRequest({ ...loginRequest, password: e.target.value })
              }}
              handleOnBlur={validatePassword}
              errorMessage={loginRequest.error.password || inputErrors.loginPassword}
              status={handlePasswordStatus()}
            />

            <div className="QForgetContainer">
              <QModalLink onClick={() => dispatch(setShowForgotModal())}>
                Esqueci a senha
              </QModalLink>
            </div>

            <QModalButton
              type="submit"
              disabled={handleDisabledButton()}
            >
              Entrar
            </QModalButton>
          </form>
        </QModalBodyRight>

      </div>

    </QModalContainer>
  );
};

export default QLoginModal;
