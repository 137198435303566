import { createAsyncThunk, createSlice, PayloadAction  } from '@reduxjs/toolkit';
import { EmploymentAuthResponse, EmploymentAuthRequest } from 'entities/employment';
import EmploymentApi from 'services/employment';
import { setFormToken, cleanFormToken } from 'services/employment/session';
import { ApiErrorType } from 'entities/error';
import { showLoading, hideLoading } from 'stores/modals';
import { convertFormToInfo } from './utils';

export const employmentGetForm = createAsyncThunk<
  any,
  EmploymentAuthRequest,
  {
    rejectValue: ApiErrorType,
  }
>(
  'employment/getForm',
  async (_payload: EmploymentAuthRequest, thunkAPI) => {
    thunkAPI.dispatch(showLoading());
    return (
      EmploymentApi.showForm()
        .then((response) => {
          thunkAPI.dispatch(hideLoading());
          return response.data;
        })
        .catch((error) => {
          thunkAPI.dispatch(hideLoading());
          return thunkAPI.rejectWithValue(error.response.data);
        })
    );
  },
);

export const employmentFormCreate = createAsyncThunk<
  EmploymentAuthResponse,
  EmploymentAuthRequest,
  {
    rejectValue: ApiErrorType,
  }
>(
  'employment/createForm',
  async (_payload: EmploymentAuthRequest, thunkAPI) => {
    thunkAPI.dispatch(showLoading());
    return (
      EmploymentApi.generateToken()
        .then((response) => {
          setFormToken({
            token: response.data.token
          });

          return EmploymentApi.createForm().then((res) => {
            thunkAPI.dispatch(hideLoading());
            return res.data
          }).catch((error) => {
            thunkAPI.dispatch(hideLoading());
            return thunkAPI.rejectWithValue(error.response.data);
          })
        })
        .catch((error) => {
          thunkAPI.dispatch(hideLoading());
          return thunkAPI.rejectWithValue(error.response.data);
        })
    );
  },
);

export const employmentAnswerForm = createAsyncThunk<
  { data: any, nextQuestionIndex?: any },
  any,
  {
    rejectValue: ApiErrorType,
  }
>(
  'employment/answerForm',
  async (payload, thunkAPI) => {
    thunkAPI.dispatch(showLoading());
    const { answers, nextQuestionIndex } = payload;

    return (
      EmploymentApi.answerForm({ answers })
        .then((response) => {
          thunkAPI.dispatch(hideLoading());
          return {
            data: response.data,
            nextQuestionIndex
        }})
        .catch((error) => {
          thunkAPI.dispatch(hideLoading());
          return thunkAPI.rejectWithValue(error.response.data);
        })
    );
  },
);

export const employmentFormComplete = createAsyncThunk<
  EmploymentAuthResponse,
  any,
  {
    rejectValue: ApiErrorType,
  }
>(
  'employment/completeForm',
  async (_payload, thunkAPI) => {
    thunkAPI.dispatch(showLoading());
    return (
      EmploymentApi.completeForm()
        .then((response) => {
          cleanFormToken();
          const userInfo = convertFormToInfo(response.data);
          
          EmploymentApi.createUserInfo({ form: userInfo })
            .then((res) => res.data)
            .catch((error) => thunkAPI.rejectWithValue(error.response.data));

          thunkAPI.dispatch(hideLoading());
          return response.data;
        })
        .catch((error) => {
          thunkAPI.dispatch(hideLoading());
          return thunkAPI.rejectWithValue(error.response.data);
        })
    );
  },
);

const initialState: { authenticated: boolean, showReviewModal: boolean, totalQuestions: number, questionLastAnswer: any, eScoreForm: any, status: '' | 'ongoing_review' | 'review' | 'start' | 'instructions' | 'ongoing' | 'confirm_finish' | 'finished', currentQuestion: any } = {
  authenticated: false,
  status: '',
  eScoreForm: null,
  currentQuestion: '',
  totalQuestions: 0,
  showReviewModal: false,
  questionLastAnswer: null
};

export const eScoreSlice = createSlice({
  name: 'eScore',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<EmploymentAuthResponse>) => {
      const { token } = action.payload;
      state.authenticated = !!token;
    },
    setStatus: (state, action: PayloadAction<'start' | 'ongoing_review' | 'review' | 'instructions' | 'ongoing' | 'confirm_finish' | 'finished'>) => {
      state.showReviewModal = action.payload === 'confirm_finish';
      state.status = action.payload;
    },
    setCurrentQuestion: (state, action: PayloadAction<any>) => {
      state.status = action.payload.status || 'ongoing';
      state.currentQuestion = action.payload.question;
      state.questionLastAnswer = action.payload.question?.answer
    },
    setShowReviewModal: (state, action: PayloadAction<any>) => {
      state.showReviewModal = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(employmentFormCreate.fulfilled, (state, action) => {
        setFormToken({
          token: action.payload.token
        });
        state.eScoreForm = action.payload;
        state.authenticated = true;
        state.status = 'instructions'
      })
      .addCase(employmentGetForm.fulfilled, (state, action) => {
        const totalQuestions = action.payload?.questions?.length;
        state.totalQuestions = totalQuestions;
        state.eScoreForm = action.payload;
        const currentQuestion = action.payload?.questions[totalQuestions - 1]?.table;
        state.currentQuestion = currentQuestion;
        state.status = currentQuestion?.answer ? 'review' : 'ongoing';
        state.showReviewModal = false;
      })
      .addCase(employmentAnswerForm.fulfilled, (state, action) => {
        state.showReviewModal = (state.eScoreForm?.progress === 100 && action.payload?.data?.progress < 100) && state.status === 'ongoing_review'
        const totalQuestions = action.payload?.data?.questions?.length;
        state.totalQuestions = totalQuestions;

        const lastQuestion = action.payload?.data?.questions[totalQuestions - 1]?.table;
        const currentQuestion = action.payload?.data?.questions[action.payload?.nextQuestionIndex]?.table || action.payload?.data?.questions[totalQuestions - 1]?.table;
        
        if(state.status === 'ongoing_review' && !lastQuestion?.answer) {
          state.showReviewModal = true;
        } else if(lastQuestion?.answer){
          state.status = 'review';
        } else {
          state.currentQuestion = currentQuestion;
        }

        if(['age', 'scholarity', 'employment_status', 'studies'].includes(currentQuestion?.id) && state.status === 'ongoing') {
          state.status = 'instructions';
        }

        state.eScoreForm = action.payload?.data;

      })
      .addCase(employmentFormComplete.fulfilled, (state, action) => {
        state.status = 'finished';
      })
  }
});

export const {
  setAuth,
  setStatus,
  setCurrentQuestion,
  setShowReviewModal
} = eScoreSlice.actions;

export default eScoreSlice.reducer;
