/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';

const QMaximize = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M2.728 14.4H4C4.21217 14.4 4.41566 14.4843 4.56569 14.6343C4.71571 14.7843 4.8 14.9878 4.8 15.2C4.8 15.4121 4.71571 15.6156 4.56569 15.7657C4.41566 15.9157 4.21217 16 4 16H0.8C0.695458 15.9987 0.592178 15.977 0.496 15.936C0.30052 15.8548 0.14518 15.6994 0.0639999 15.504C0.0230072 15.4078 0.00126399 15.3045 0 15.2L0 12C0 11.7878 0.0842854 11.5843 0.234315 11.4343C0.384344 11.2843 0.587827 11.2 0.8 11.2C1.01217 11.2 1.21566 11.2843 1.36569 11.4343C1.51571 11.5843 1.6 11.7878 1.6 12V13.272L6.232 8.63197C6.30637 8.55699 6.39485 8.49747 6.49234 8.45686C6.58983 8.41624 6.69439 8.39533 6.8 8.39533C6.90561 8.39533 7.01017 8.41624 7.10766 8.45686C7.20515 8.49747 7.29363 8.55699 7.368 8.63197C7.44298 8.70634 7.5025 8.79482 7.54311 8.89231C7.58373 8.9898 7.60464 9.09436 7.60464 9.19997C7.60464 9.30558 7.58373 9.41014 7.54311 9.50763C7.5025 9.60512 7.44298 9.6936 7.368 9.76797L2.728 14.4ZM15.2 4.79997C14.9878 4.79997 14.7843 4.71568 14.6343 4.56566C14.4843 4.41563 14.4 4.21214 14.4 3.99997V2.72797L9.768 7.36797C9.61736 7.51861 9.41304 7.60324 9.2 7.60324C8.98696 7.60324 8.78264 7.51861 8.632 7.36797C8.48136 7.21733 8.39673 7.01301 8.39673 6.79997C8.39673 6.58693 8.48136 6.38261 8.632 6.23197L13.272 1.59997H12C11.7878 1.59997 11.5843 1.51568 11.4343 1.36565C11.2843 1.21563 11.2 1.01214 11.2 0.79997C11.2 0.587796 11.2843 0.384313 11.4343 0.234283C11.5843 0.0842543 11.7878 -3.05176e-05 12 -3.05176e-05H15.2C15.3045 0.0012331 15.4078 0.0229769 15.504 0.0639696C15.6995 0.145149 15.8548 0.30049 15.936 0.49597C15.977 0.592148 15.9987 0.695428 16 0.79997L16 3.99997C16 4.21214 15.9157 4.41563 15.7657 4.56566C15.6157 4.71568 15.4122 4.79997 15.2 4.79997Z" fill="#637281" />
  </svg>

);

export default QMaximize;
