import QMaximize from 'assets/icons/QMaximize';
import QMinimize from 'assets/icons/QMinimize';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useAppDispatch } from 'stores';
import { resizeIframeModal } from 'stores/modals';
import './styles.css';

export interface QModalResizeProps {
  fnCallback: () => void,
  className?: string,
  initialStatus?: boolean
}

const QModalResize = (Props: QModalResizeProps) => {
  const { fnCallback, className, initialStatus} = Props;
  const [status, setStatus] = useState<boolean>(initialStatus || false);
  const dispatch = useAppDispatch;

  const handleClick = useCallback(() => {
    setStatus(!status);
    fnCallback();
    dispatch(resizeIframeModal(!status))
  }, [status]);

  return (
    <div className={classNames("QResize", className)} onClick={() => handleClick()} role="presentation">
      <div className="QResizeIconContainer">
        {
          status ? (<QMaximize className="QResizeIcon" />) : (<QMinimize className="QResizeIcon" />)
        }
      </div>
    </div>
  );
};

export default QModalResize;
