/* eslint-disable react/jsx-no-useless-fragment */

import QLoading from 'components/atoms/QLoading';
import { ToastContextProvider } from 'context/ToastContext';
import React from 'react';
import { Provider } from 'react-redux';
import Rollbar from 'rollbar';
import { Provider as RollBarProvide, ErrorBoundary, useRollbarPerson } from '@rollbar/react';
import AmplitudeProvider from 'providers/AmplitudeProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { OfferContextProvider } from 'context/OfferContext';
import { PaymentContextProvider } from 'context/PaymentContext';
import useAuth from "hooks/useAuth";
import Routes from 'routes';
import { store } from 'stores';

const persistor = persistStore(store);

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_APP_KEY,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.REACT_APP_ENV || 'development',
};

const RollbarUserProvider: React.FC = ({ children }) => {
  const { id, email } = useAuth();

  useRollbarPerson({ id, email });

  return <>{children}</>;
}

const App: React.FC = () => (
  <Router>
    <RollBarProvide config={rollbarConfig}>
      <ErrorBoundary>
        <Provider store={store} >
          <RollbarUserProvider>
            <AmplitudeProvider>
              <PersistGate loading={<QLoading open />} persistor={persistor}>
                <React.Suspense fallback={false}>
                  <ToastContextProvider>
                    <OfferContextProvider>
                      <PaymentContextProvider>
                        <Routes />
                      </PaymentContextProvider>
                    </OfferContextProvider>
                  </ToastContextProvider>
                </React.Suspense>
              </PersistGate>
            </AmplitudeProvider>
          </RollbarUserProvider>
        </Provider>
      </ErrorBoundary>
    </RollBarProvide>
  </Router>
);
export default App;
