import axios from 'axios';
import { useAppDispatch } from 'stores';
import { setAuth } from 'stores/eScore';
import { getFormToken } from './session';

const EmploymentApi = axios.create({
  baseURL: process.env.REACT_APP_API,
});

EmploymentApi.interceptors.request.use((config: any) => {
  let { headers } = config;

  const token = getFormToken();
  const retryCount = config.retryCount || 0;

  if (token) {
    headers = headers || {};
    headers.Authorization = `Bearer ${token}`;
    useAppDispatch(setAuth({ token }));
  }

  return {
    ...config,
    headers,
    retryCount,
  };
});

export default EmploymentApi;
