/* eslint-disable react/jsx-no-useless-fragment */

import { ReactNode, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { RootState } from 'stores';

import { init, Identify, identify, setUserId } from '@amplitude/analytics-browser';

const AmplitudeProvider = ({ children }: { children: ReactNode }) => {
  const [initialized, setInitialized] = useState(false)
  const { user } = useSelector((state: RootState) => state);

  const { authenticated, id, email, name } = user;

  const setup = async () => {
    await init(process.env.REACT_APP_AMPLITUDE_KEY!, undefined, {
      attribution: {
        trackNewCampaigns: true,
      },
    });

    setTimeout(() => {
      setInitialized(true);
    }, 500);
  }

  useEffect(() => {
    if (!initialized) {
      setup();
    }
  }, [initialized])

  useEffect(() => {
    if (!initialized) {
      return;
    }

    setUserId(id)

    if (authenticated) {
      const event = new Identify();

      if (email && name) {
        event.set('email', email);
        event.set('name', name);
      }

      identify(event);
    }
  }, [initialized, user])

  return <>{children}</>;
};

export default AmplitudeProvider;
