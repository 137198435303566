import { Outlet } from "react-router-dom";
import React from 'react';

const Header = React.lazy(() => import('components/molecules/QHeader'));
const Footer = React.lazy(() => import('components/molecules/QFooter'));

const WithHeaderRoutes = () => (
  <>
    <Header />
    <div style={{ minHeight: 'calc(100vh - 64px)' }}>
      <Outlet />
    </div>
    <Footer />
  </>
);

export default WithHeaderRoutes;