import Modal from '@mui/material/Modal';
import classnames from 'classnames';
import React, { ReactElement } from 'react';
import './styles.css';

export interface QModalContainerProps {
  children: ReactElement[] | ReactElement,
  open: boolean,
  id?: string,
  type: 'Login' | 'Cadastro' | 'Forget' | 'Loading' | 'Default' | 'Refund' | 'Welcome' | 'Bottom',
}
const QModalContainer = (Props: QModalContainerProps) => {
  const { children, open, type, id } = Props;

  return (
    <Modal open={open} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div id={id} className={classnames(`QModalContainer${type}`)}
      >
        {children}
      </div>
    </Modal>
  );
};

export default QModalContainer;
