/* eslint-disable arrow-body-style */

const sorter = (a: any, b: any) => (
  a.order - b.order
);

const courseMapper = (course: any) => ({
  id: course.id,
  duration: course.duration,
  createdAt: course.created_at,
  name: course.name,
  description: course.description,
  benefits: course.offer.benefits.map(({ text } : any) => text),
  price: course.offer.price.cents / 100.0,
  referencePrice: course.offer.reference_price.cents / 100.0,
  productors: course.productors.map(({ name }: any) => name),
  productor: course.productor,
  tags: course.tags.map(({ name }: any) => name),
  instructors: course.instructors,
  ratingAverage: course.ratings.average,
  ratingCount: course.ratings.count,
  slug: course.slug,
  sections: course.sections.sort(sorter).map(({ id, name, topics, resources }: any) => ({
    id,
    name,
    resources,
    topics: topics.sort(sorter).map((topic: any) => ({
      id: topic.id,
      name: topic.name,
      contents: topic?.contents?.sort(sorter).map((content: any) => ({
        id: content.id,
        name: content.name,
      })),
      resources: topic.resources
    })),
  })),
  resources: course.resources,
  images: course.images,
  offerId: course.offer.id,
  offer: course.offer,
});

export default courseMapper;
