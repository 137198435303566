import QLoading from 'components/atoms/QLoading';
import QCadastroModal from 'components/organisms/QCadastroModal';
import QForgetPasswordModal from 'components/organisms/QForgetPasswordModal';
import QLoginModal from 'components/organisms/QLoginModal';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { useAppDispatch } from 'stores';
import TagManager from 'react-gtm-module';
import {
  hideCadastroModal, hideLoginModal, setShowForgotModal,
} from 'stores/modals';
import { setUserInfo } from 'stores/user';
import { RootState } from 'stores/rootReducers';
import { RecoverPasswordContextProvider } from 'context/RecoverPasswordContext';
import QCertificateModal from 'components/organisms/QCertificateModal';
import QModalNewsletter from 'components/molecules/QModalNewsletter';
import * as Components from './components';
import { PrivateRoute } from './privateRoute';
import WithHeaderRoutes from './withHeaderRoutes';


const AppRoutes = () => (
  <Routes>
    <Route path="" element={<WithHeaderRoutes />}>
      <Route path="/" element={<Components.NewHome />} />
      <Route path="" >
        <Route path="/:productorSlug" element={<Components.Productor />} />
        <Route path="/:productorSlug/:career" element={<Components.Productor />} />
      </Route>
      <Route path="/cursos/:courseId" element={<Components.ProductPage />} />
      <Route path="/termos/politica-de-privacidade" element={<Components.Privacy />} />
      <Route path="/termos/termos-de-uso" element={<Components.Terms />} />
      <Route path="/termos/termos-de-adesao" element={<Components.Accession />} />
      <Route path="/termos/termos-de-adesao-anitta" element={<Components.Accession anitta />} />
      <Route path="/termos/politica-de-cookies" element={<Components.Cookies />} />
      <Route path="/checkout/:offerId" element={<Components.Checkout />} />
      <Route path="/cursos-de-:tagSlug" element={<Components.Careers />} />
      <Route path="/oauth/google" element={<Components.OAuthGoogle />} />
      <Route path="/oauth/sso" element={<Components.OAuthSSO />} />
      <Route path="/:productorSlug/cursos/:courseSlug" element={<Components.ProductPage />} />
      <Route element={<PrivateRoute />}>
        <Route path="/meus-cursos" element={<Components.MyCourses />}/>
        <Route path="/meus-cursos/:courseId" element={<Components.Course />} />
        <Route path="/meus-cursos/:courseId" element={<Components.Course />} />
        <Route path="/meus-cursos/:courseId/:sectionId" element={<Components.Course />} />
        <Route path="/meus-cursos/:courseId/:sectionId/:topicId" element={<Components.Course />} />
        <Route path="/meus-cursos/:courseId/:sectionId/:topicId/:contentId" element={<Components.Course />} />
        <Route path="/conta/pedidos" element={<Components.MyOrders />} />
        <Route path="/conta/pedidos/:orderId" element={<Components.MyOrderShow />} />
      </Route>
    </Route>
    <Route element={<PrivateRoute />}>
      <Route path="/e-score/insights" element={<Components.EScoreInsights />} />
    </Route>
    <Route path="/sobre" element={<Components.About/>} />
    <Route path="/e-score" element={<Components.EScore />} />
  </Routes>
);

const Router = () => {
  const dispatch = useAppDispatch;
  const modal = useSelector((state: RootState) => (state.modal));
  const user = useSelector((state: RootState) => (state.user));

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'login_status',
        login_details: {
          id: user?.id,
          success: true,
        }
      }
    });

    if (user.authenticated) {
      if (user.name === '') {
        dispatch(setUserInfo());
      }
    }
  }, [user]);

  return (
    <>
      <AppRoutes />

      <QLoading open={modal.showLoadingModal} />
      <QCadastroModal
        open={modal.showCadastroModal.active}
        fnCallBack={() => dispatch(hideCadastroModal())}
      />
      <QLoginModal
        open={modal.showLoginModal.active}
        fnCallBack={() => dispatch(hideLoginModal())}
      />
      <QCertificateModal />
      <RecoverPasswordContextProvider>
        <QForgetPasswordModal
          open={modal.showForgotModal}
          fnCallBack={() => dispatch(setShowForgotModal())}
        />
      </RecoverPasswordContextProvider>
      <QModalNewsletter />
    </>
  );
};
export default Router;
