import validator from 'validator';

export type UserType = {
  authenticated: boolean;
  id: string | null;
  email: string | null;
  name: string;
  redirectLocation: string;
  status?: string;
  welcomed?: boolean;
  newsletterSub?: boolean;
  infos: Record<string, any>
};

export type UserInfo = {
  name: string;
  id: string;
  email: string;
};

export interface AuthLoginResponse {
  token: string,
  refresh_token: string,
  name: string,
}

export interface LoginRequest {
  email: string,
  password: string,
  error: {
    email: string,
    password: string,
  },
}

export interface GoogleLoginRequest {
  token: string,
  code?: string,
  error?: string,
}

export interface FacebookLoginRequest {
  token: string,
  error?: string,
}

export interface SignUpRequest {
  error: {
    email: string,
    password: string,
    name: string,
  },
  email: string,
  password: string,
  name: string,
}

export const loginValidation = (payload: LoginRequest) => {
  const { email, password } = payload;

  const errors = {
    email: '',
    password: '',
  };

  if (validator.isEmpty(email)) {
    errors.email = 'Digite um e-mail';
  }

  if (validator.isEmpty(password)) {
    errors.password = 'Digite uma senha!';
  }

  if (!validator.isEmpty(email) && !validator.isEmail(email)) {
    errors.email = 'Digite um e-mail válido!';
  }

  return {
    errors,
    isValid: Object.values(errors).filter(Boolean).length === 0
  };
};

export const signUpValidation = (payload: SignUpRequest) => {
  const { name, email, password } = payload;

  const errors = {
    name: '',
    email: '',
    password: '',
  };

  if (validator.isEmpty(name)) {
    errors.name = 'Digite um nome!';
  }

  if (validator.isEmpty(email)) {
    errors.email = 'Digite um e-mail';
  }

  if (validator.isEmpty(password)) {
    errors.password = 'Digite uma senha!';
  }

  if (!validator.isEmpty(password) && password.length < 8) {
    errors.password = 'Sua senha precisa ter no mínimo de 8 caracteres';
  }

  if (!validator.isEmpty(email) && !validator.isEmail(email)) {
    errors.email = 'Digite um e-mail válido!';
  }

  return {
    errors,
    isValid: Object.values(errors).filter(Boolean).length === 0
  };
};
