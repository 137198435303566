export const formatInput = (
  value: string,
  format: string,
  onlyNumbers = false,
): string => {
  let tempValue = value;
  let newValue = '';
  let formatIndex = 0;

  if (onlyNumbers) {
    tempValue = value.replace(/[^0-9]/, '');
  }

  if (tempValue.length < 1) {
    return '';
  }

  for (let i = 0; i < tempValue.length; i += 1) {
    if (formatIndex < format.length) {
      while (format[formatIndex] !== '#') {
        newValue += format[formatIndex];
        formatIndex += 1;
      }
      newValue += tempValue[i];
      formatIndex += 1;
    }
  }

  return newValue;
};
