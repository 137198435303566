import React from 'react';

const NewHome = React.lazy(() => import('screens/landing_pages/new/home'));
const Productor = React.lazy(() => import('screens/landing_pages/new/productor'));
const ProductPage = React.lazy(() => import('screens/product'));
const Course = React.lazy(() => import('screens/course'));
const Privacy = React.lazy(() => import('screens/privacy'));
const Terms = React.lazy(() => import('screens/terms'));
const Accession = React.lazy(() => import('screens/accession'));
const Cookies = React.lazy(() => import('screens/cookies'));
const MyCourses = React.lazy(() => import('screens/mycourses'));
const MyOrders = React.lazy(() => import('screens/myorders'));
const MyOrderShow = React.lazy(() => import('screens/myorders/show'));
const Checkout = React.lazy(() => import('screens/checkout'));
const Careers = React.lazy(() => import('screens/careers'));
const OAuthGoogle = React.lazy(() => import('screens/oauth/google'));
const OAuthSSO = React.lazy(() => import('screens/oauth/sso'));
const About = React.lazy(() => import('screens/landing_pages/new/about'));
const EScore = React.lazy(() => import('screens/tailwind/eScore'));
const EScoreInsights = React.lazy(() => import('screens/tailwind/insights'));

export {
  NewHome,
  Productor,
  ProductPage,
  Course,
  Privacy,
  Terms,
  Accession,
  Cookies,
  MyCourses,
  MyOrders,
  MyOrderShow,
  Checkout,
  Careers,
  OAuthGoogle,
  OAuthSSO,
  About,
  EScore,
  EScoreInsights
};